import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ContactPage() {
  return (
    <Layout>
      <SEO
        title="Contact"
        keywords={['contact', 'takanoha', 'london']}
      />

      <form
        className="mx-auto md:w-1/2"
        method="post"
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success"
      >
        <p className="leading-loose mb-4">
          Get in touch
        </p>

        <input type="hidden" name="form-name" value="contact" />

        <label htmlFor="first-name" className="required">
          First Name
        </label>

        <input
          id="first-name"
          type="text"
          name="first-name"
          placeholder="First name"
          required
        />

        <label htmlFor="last-name" className="required">
          Last Name
        </label>

        <input
          id="last-name"
          type="text"
          name="last-name"
          placeholder="Last name"
          required
        />

        <label htmlFor="company">
          Company
        </label>

        <input
          id="company"
          type="text"
          name="company"
          placeholder="Company name"
          required
        />

        <label htmlFor="phone">
          Phone Number
        </label>

        <input
          id="phone"
          type="tel"
          name="phone"
          placeholder="Phone #"
        />

        <label htmlFor="email" className="required">
          Email
        </label>

        <input
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          required
        />

        <label htmlFor="message" className="required">
          Message
        </label>

        <textarea
          placeholder="Say something..."
          id="message"
          rows="8"
          name="message"
          required
        />

        <button className="button--primary" type="submit">
          Submit
        </button>
      </form>
    </Layout>
  );
}

export default ContactPage;
